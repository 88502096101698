import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import { FieldValues, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
// import Root from "./Root";


const Login1 = React.lazy(() => import("../pages/login"));
// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));


// dashboard
const IndexDashboard = React.lazy(
  () => import("../pages/dashboard/home/")
);


// apps

const Perfil = React.lazy(() => import("../pages/apps/App/perfil"));
const Contrasena = React.lazy(() => import("../pages/apps/App/contrasena"));
const Idioma = React.lazy(() => import("../pages/apps/App/idioma"));
const Acerca = React.lazy(() => import("../pages/apps/App/acerca"));

const AveriasApp = React.lazy(() => import("../pages/apps/Averias/"));
const AveriasVacia = React.lazy(() => import("../pages/apps/Averias/vacia"));
const AveriasDetalle = React.lazy(() => import("../pages/apps/Averias/detalle"));
const AveriasNew = React.lazy(() => import("../pages/apps/Averias/addAveria"));
const AveriasEdit = React.lazy(() => import("../pages/apps/Averias/editAveria"));


const UsuariosApp = React.lazy(() => import("../pages/apps/Usuarios/"));
const UsuarioDetalle = React.lazy(() => import("../pages/apps/Usuarios/detalle"));
const UsuarioNew = React.lazy(() => import("../pages/apps/Usuarios/addusuario"));
const UsuarioEdit = React.lazy(() => import("../pages/apps/Usuarios/editusuario"));


const RolesApp = React.lazy(() => import("../pages/apps/Roles/"));
const RolDetalle = React.lazy(() => import("../pages/apps/Roles/detalle"));
const RolNew = React.lazy(() => import("../pages/apps/Roles/addrol"));
const RolEdit = React.lazy(() => import("../pages/apps/Roles/editrol"));
const RolListUser = React.lazy(() => import("../pages/apps/Roles/listUser"));


const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));




export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
// modificar

const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "calendar",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "home",
      element: <Navigate to="/dashboard/home" />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/home",
      name: "Home",
      element: <IndexDashboard />,
      route: PrivateRoute,
    },
  ],
};


const appAppRoutes: RoutesProps = {
  path: "/apps/app",
  name: "Solatrans",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "i-averias",
  header: "Apps",
  children: [
    {
      path: "/apps/app/perfil",
      name: "Perfil",
      element: <Perfil />,
      route: PrivateRoute,
    },
    {
      path: "/apps/app/contrasena",
      name: "Contraseña",
      element: <Contrasena />,
      route: PrivateRoute,
    },
    {
      path: "/apps/app/idioma",
      name: "Idiomas",
      element: <Idioma />,
      route: PrivateRoute,
    },
    {
      path: "/apps/app/acerca",
      name: "Acerca de la App",
      element: <Acerca />,
      route: PrivateRoute,
    }


  ],
};

const averiasAppRoutes: RoutesProps = {
  path: "/apps/averias",
  name: "Averias",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "i-averias",
  element: <AveriasApp averiaDetalle={[]} />,
  header: "Apps",
  children: [
    {
      path: "/apps/Averias/addAveria",
      name: "Nueva Averia",
      element: <AveriasNew />,
      route: PrivateRoute,
    },
    {
      path: "/apps/Averias/editAveria/*",
      name: "Editar Averia",
      element: <AveriasEdit />,
      route: PrivateRoute,
    },
    {
      path: "/apps/Averias/detalle",
      name: "Detalle Averia",
      element: <AveriasDetalle />,
      route: PrivateRoute,
    }
    ,
    {
      path: "/apps/Averias/vacia",
      name: "Detalle Averia vacia",
      element: <AveriasVacia />,
      route: PrivateRoute,
    }


  ],
};

const usuariosAppRoutes: RoutesProps = {
  path: "/apps/usuarios",
  name: "Usuarios",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "i-averias",
  element: <UsuariosApp />,
  header: "Apps",
  children: [
    {
      path: "/apps/usuarios/addusuario",
      name: "Nuevo Usuario",
      element: <UsuarioNew />,
      route: PrivateRoute,
    },
    {
      path: "/apps/usuarios/editusuario",
      name: "Editar Usuario",
      element: <UsuarioEdit />,
      route: PrivateRoute,
    },
    {
      path: "/apps/usuarios/detalle",
      name: "Detalle Usuario",
      element: <UsuarioDetalle />,
      route: PrivateRoute,
    }


  ],
};


const rolesAppRoutes: RoutesProps = {
  path: "/apps/roles",
  name: "Roles",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "i-averias",
  element: <RolesApp />,
  header: "Apps",
  children: [
    {
      path: "/apps/roles/addrol",
      name: "Nuevo Rol",
      element: <RolNew />,
      route: PrivateRoute,
    },
    {
      path: "/apps/roles/editrol",
      name: "Editar Rol",
      element: <RolEdit />,
      route: PrivateRoute,
    },
    {
      path: "/apps/roles/detalle",
      name: "Detalle Rol",
      element: <RolDetalle />,
      route: PrivateRoute,
    },
    {
      path: "/apps/roles/listUser",
      name: "Listado",
      element: <RolListUser />,
      route: PrivateRoute,
    }



  ],
};













const appRoutes = [
  appAppRoutes,
  averiasAppRoutes,
  usuariosAppRoutes,
  rolesAppRoutes,
];



// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/login",
    name: "Home",
    element: <Login1 />,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...appRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
